var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"accordion"},[_c('header',{staticClass:"accordion-header",class:{'accordion-header--dark': _vm.darkMode},on:{"click":function($event){$event.preventDefault();return _vm.expandBody.apply(null, arguments)}}},[_c('ChevronDown',{staticClass:"accordion-header__icon",class:{
        'accordion-header__icon--expanded': _vm.expanded,
        'accordion-header__icon--dark': _vm.darkMode,
      }}),_c('span',{staticClass:"accordion-header__title",class:{'accordion-header__title--dark': _vm.darkMode}},[_vm._v(" Comentários do Professor ")])],1),_c('section',{ref:"expandableBody",staticClass:"accordion-body",class:{
      'accordion-body--expanded': _vm.expanded,
      'accordion-body--dark': _vm.darkMode,
    }},[_c(_vm.content,{tag:"component",attrs:{"data":_vm.data.data,"darkMode":_vm.darkMode}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }