
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import { ProfileComponentBody } from '@/models';

import PublishedAssignmentProfile from './ProfileTypes/PublishedAssignmentProfile.vue';
import PendingAssignmentProfile from './ProfileTypes/PendingAssignmentsProfile.vue';
import HorizontalProfile from './ProfileTypes/HorizontalProfile.vue';

const {
  PUBLISHED_ASSIGNMENT_BODY,
  PENDING_ASSIGNMENTS_BODY,
  HORIZONTAL_PROFILE_BODY,
} = ProfileComponentBody;

const profileBody: {
  [index: string]: VueConstructor;
} = {
  [HORIZONTAL_PROFILE_BODY]: HorizontalProfile,
  [PUBLISHED_ASSIGNMENT_BODY]: PublishedAssignmentProfile,
  [PENDING_ASSIGNMENTS_BODY]: PendingAssignmentProfile,
};

@Component({
  name: 'ProfileFactory',
})
export default class ProfileFactory extends Vue {
  public static getProfileBody(type: ProfileComponentBody): VueConstructor {
    return profileBody[type];
  }
}
