
import { Vue, Prop, Component } from 'vue-property-decorator';

import { PillColor } from '@/models';

@Component({
  name: 'Pill',
})

export default class Pill extends Vue {
  @Prop({ default: PillColor.BLUE }) color: PillColor;
  @Prop({ default: '' }) text: string;
}
