
import {
  Component,
  Model,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'StatusFilter',
})
export default class StatusFilter extends Vue {
  @Prop({ default: 3 }) public publisheds: number;
  @Prop({ default: 6 }) public pendings: number;
  @Model('change', { type: Boolean }) publishedTabActive: boolean;

  public change() {
    this.$emit('change', !this.publishedTabActive);
  }
}
