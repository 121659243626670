
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import { AccordionTypes } from '@/models';
import ProfessorComments from './AccordionType/ProfessorComments.vue';

const { PROFESSOR_COMMENTS } = AccordionTypes;

const accordionBody: {
  [index: string]: VueConstructor;
} = {
  [PROFESSOR_COMMENTS]: ProfessorComments,
};

@Component({
  name: 'AccordionFactory',
})
export default class AccordionFactory extends Vue {
  public static getAccordionBody(type: AccordionTypes): VueConstructor {
    return accordionBody[type];
  }
}
