
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { AccordionDataModel } from '@/models';
import { AccordionUtils } from '@/utils';
import ChevronDown from '@/assets/icons/ChevronDown.svg';
import AccordionFactory from './AccordionFactory.vue';

@Component({
  name: 'Accordion',
  components: {
    ChevronDown,
  },
})
export default class Accordion extends Vue {
  @Prop() data: AccordionDataModel;

  $refs: {
    expandableBody: HTMLElement;
  };

  mounted() {
    this.expandBody(0);
  }

  public expanded = this.data.collapsed ?? true;

  private accordionContent: VueConstructor | null = null;

  get content(): VueConstructor | null {
    return this.data
      ? AccordionFactory.getAccordionBody(this.data.body)
      : this.accordionContent;
  }

  set content(body: VueConstructor | null) {
    this.accordionContent = body;
  }

  get darkMode(): boolean {
    return this.data.darkMode ?? false;
  }

  public expandBody(delay = 500) {
    this.expanded = !this.expanded;
    AccordionUtils.toggleExpandableElement(this.$refs.expandableBody, this.expanded, delay);

    if (!this.expanded) setTimeout(() => this.$emit('onCollapse'), 500);
  }
}
