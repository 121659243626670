
import { Vue, Prop, Component } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import { ProfileComponentBody, Student } from '@/models';

import ProfileFactory from './ProfileFactory.vue';

@Component({
  name: 'Profile',
  components: {
    ProfileFactory,
  },
})
export default class Profile extends Vue {
  @Prop() data: Student;
  @Prop() opt: object;
  @Prop() body: ProfileComponentBody;
  @Prop() requesting: boolean;

  public bodyComponent: VueConstructor | null = null;

  mounted() {
    this.bodyComponent = ProfileFactory.getProfileBody(this.body);
  }
}
