
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Header from '@/components/Header/Header.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import AccordionAssignments from '@/components/MyAssignmentsPage/AccordionAssignments.vue';
import StatusFilter from '@/components/MyAssignmentsPage/StatusFilter.vue';
import Profile from '@/components/Profile/Profile.vue';
import Footer from '@/components/Footer/Footer.vue';
import ProfessorComments from '@/components/Accordion/AccordionType/ProfessorComments.vue';
import {
  DeliveryStatus, MyAssignments, MyWork, CommentModel, WorkToFillEvents,
} from '@/models';
import { EventBus } from '@/utils';
import Close from '@/assets/icons/Close.svg';

const { OPEN_COMMENTS } = WorkToFillEvents;

@Component({
  name: 'MyAssignmentsPage',
  components: {
    Header,
    Profile,
    StatusFilter,
    AccordionAssignments,
    ButtonDefault,
    Footer,
    ProfessorComments,
    Close,
  },
  computed: {
    ...mapGetters({
      loading: 'myAssignmentsPage/isRequestingPending',
      myAssignments: 'myAssignmentsPage/myAssignments',
    }),
  },
})
export default class MyAssignmentsPage extends Vue {
  public myAssignments!: MyAssignments[];
  public loading!: boolean;
  public publishedTabActive = false;
  private professorComments: CommentModel[] = [];
  private showProfessorComments = false;

  public get qtdPendings() {
    return this.filterAssignments(false).length;
  }

  public get qtdPublisheds() {
    return this.filterAssignments(true).length;
  }

  get comments(): CommentModel[] {
    return this.professorComments;
  }

  set comments(data: CommentModel[]) {
    this.professorComments = data;
  }

  get showComments(): boolean {
    return this.showProfessorComments;
  }

  set showComments(show: boolean) {
    this.showProfessorComments = show;
  }

  mounted() {
    this.subscribe();
  }

  beforeDestroy() {
    this.unsubscribe();
  }

  private subscribe() {
    EventBus.$on(OPEN_COMMENTS, this.toggleProfessorComments);
  }

  private unsubscribe() {
    EventBus.$off(OPEN_COMMENTS, this.toggleProfessorComments);
  }

  private filterAssignments(byPublisheds = false) {
    const requirements = (w: MyWork) => (byPublisheds
      ? w.delivery?.status === DeliveryStatus.PUBLISHED
      : w.delivery?.status !== DeliveryStatus.PUBLISHED);

    return this.myAssignments
      .map((a) => a.assignments)
      .flat()
      .filter((b) => requirements(b));
  }

  private toggleProfessorComments(comments?: CommentModel[]) {
    this.comments = comments || [];
    this.showComments = !this.showComments;
  }
}
