
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CommentModel } from '@/models';
import { DateUtils } from '@/utils';

@Component({
  name: 'ProfessorComments',
})
export default class ProfessorComments extends Vue {
  @Prop() data: CommentModel[];
  @Prop({ default: false }) darkMode: boolean;
  @Prop() title: string;

  public ellapsed(date: Date): string {
    return DateUtils.elapsedTime(new Date(date));
  }

  public ptBRDate(date: Date): string {
    return DateUtils.toString(date);
  }
}
