
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import Pill from '@/components/shared/Pill.vue';
import Accordion from '@/components/Accordion/Accordion.vue';
import TextEditor from '@/components/shared/TextEditor.vue';

import {
  ButtonDefaultColors,
  DeliveryStatus,
  DeliveryStatusColor,
  DeliveryStatusText,
  MyWork,
  PillColor,
  AccordionDataModel,
  AccordionTypes,
  WorkToFillEvents,
  CommentModel,
} from '@/models';

import { DateUtils, GradeUtils, EventBus } from '@/utils';

const {
  PUBLISHED,
  IN_RECOVERY,
  NOT_STARTED,
  EXPIRED,
  AWAITING_EVALUATION,
} = DeliveryStatus;

const { PROFESSOR_COMMENTS } = AccordionTypes;
const { OPEN_COMMENTS, CLOSE_COMMENTS } = WorkToFillEvents;

@Component({
  name: 'WorkToFill',
  components: {
    Accordion,
    ButtonDefault,
    Pill,
    TextEditor,
  },
  computed: {
    ...mapGetters('headerDisplay', {
      mediumView: 'mediumView',
    }),
  },
  methods: {
    ...mapActions('headerDisplay', {
      checkWidthSize: 'checkWidthSize',
    }),
  },
})
export default class WorkToFill extends Vue {
  @Prop({}) public work: MyWork;

  $refs: {
    item: HTMLElement;
  };

  public DateUtils = DateUtils;
  public ButtonDefaultColors = ButtonDefaultColors;
  public PillColor = PillColor;
  public DeliveryStatusColor = DeliveryStatusColor;
  public DeliveryStatusText = DeliveryStatusText;
  public EXPIRED = EXPIRED;
  public showComments = false;
  public mediumView: boolean;

  private statusTextButton = {
    [DeliveryStatus.DRAFT]: 'RESPONDER',
    [DeliveryStatus.PUBLISHED]: 'VER ASSIGNMENT',
    [DeliveryStatus.NOT_STARTED]: 'RESPONDER',
    [DeliveryStatus.EXPIRED]: 'VER ASSIGNMENT',
    [DeliveryStatus.IN_RECOVERY]: 'EDITAR',
    [DeliveryStatus.PENDING_STUDENT_CORRECTION]: 'EDITAR',
    [DeliveryStatus.AWAITING_EVALUATION]: 'VER ASSIGNMENT',
  };

  public accordionData: AccordionDataModel = {
    body: PROFESSOR_COMMENTS,
    collapsed: false,
    data: this.professorComments,
    darkMode: true,
  };

  public checkWidthSize: () => void;

  public get buttonText() {
    return this.statusTextButton[this.work.delivery?.status || DeliveryStatus.NOT_STARTED];
  }

  public get tagText() {
    const expired = DateUtils.checkExpiredDate(this.work.recoveryDueDate);
    const status = expired
      && this.work.delivery?.status === NOT_STARTED
      && !this.work.delivery.aditionalDueDate
      ? EXPIRED
      : this.work.delivery?.status as DeliveryStatus;

    const grade = Number(this.work.delivery?.aditionalGrade
      ?? this.work.delivery?.recoveryGrade
      ?? this.work.delivery?.grade);

    const tag = {
      [PUBLISHED]: this.work.delivery?.grade ? 'APROVADO' : DeliveryStatusText[PUBLISHED],
      [IN_RECOVERY]: DeliveryStatusText[IN_RECOVERY],
    };

    if (
      status === PUBLISHED
      || status === IN_RECOVERY
    ) return `${tag[status]} - NOTA ${GradeUtils.numberToConcept(grade)}`;

    return DeliveryStatusText[status];
  }

  public get showButtonComment() {
    return this.work.delivery?.professorComments?.length;
  }

  public get showButton() {
    const today = new Date();
    return this.work.delivery?.id
    || today < new Date(this.work.recoveryDueDate)
    || today < new Date(this.work.delivery?.aditionalDueDate as string);
  }

  public get pillDateText(): string {
    const status: DeliveryStatus = this.work.delivery ? this.work.delivery?.status : NOT_STARTED;
    const deliveryDate = this.work.delivery?.isInRecovery
      ? (this.work.delivery?.aditionalDate
      ?? this.work.delivery?.recoveryDate)
      : this.work.delivery?.deliveryDate;
    const dueDate = this.work.dueDate
      ? DateUtils.toString(this.work.dueDate)
      : '00/00/0000';
    const recoveryDueDate = this.work.recoveryDueDate
      ? DateUtils.toString(this.work.recoveryDueDate)
      : '00/00/0000';
    const aditionalDueDate = this.work.delivery?.aditionalDueDate
      ? DateUtils.toString(this.work.delivery?.aditionalDueDate)
      : undefined;

    switch (status) {
      case PUBLISHED:
        return `PUBLICADO EM ${DateUtils.toString(this.work.delivery?.publicationDate as string)}`;
      case AWAITING_EVALUATION:
        return `ENTREGUE EM ${DateUtils.toString(deliveryDate as string)}`;
      default:
        return this.work.delivery?.isInRecovery
          ? `ENTREGA EM RECUPERAÇÃO ATÉ ${aditionalDueDate ?? recoveryDueDate}`
          : `ENTREGA ATÉ ${dueDate}`;
    }
  }

  get professorComments(): CommentModel[] {
    return this.work.delivery?.professorComments ?? [];
  }

  mounted() {
    this.checkWidthSize();
    this.subscribe();
  }

  beforeDestroy() {
    this.unsubscribe();
  }

  private subscribe() {
    window.addEventListener('resize', this.checkWidthSize);
    EventBus.$on(CLOSE_COMMENTS, this.closeComments);
  }

  private unsubscribe() {
    window.removeEventListener('resize', this.checkWidthSize);
    EventBus.$off(CLOSE_COMMENTS, this.closeComments);
  }

  public onSeeOrFill(see: boolean) {
    const seeOrFill = see ? 'onSee' : 'onFill';
    const id = see ? this.work.delivery?.id : this.work.id;
    this.$emit(seeOrFill, id);
  }

  public toggleComments() {
    if (this.mediumView) {
      EventBus.$emit(OPEN_COMMENTS, this.work.delivery?.professorComments);
      return;
    }
    EventBus.$emit(CLOSE_COMMENTS, this.work.delivery?.id);
    this.accordionData.data = this.professorComments;
    this.showComments = !this.showComments;
  }

  private closeComments(id: number) {
    if (id && id !== this.work.delivery?.id) this.showComments = false;
  }
}
