
import { Component, Prop, Vue } from 'vue-property-decorator';

import ChevronDownLight from '@/assets/icons/ChevronDownLight.svg';

import Header from '@/components/Header/Header.vue';
import WorkToFill from '@/components/MyAssignmentsPage/WorkToFill.vue';
import Footer from '@/components/Footer/Footer.vue';

import {
  ButtonDefaultColors,
  DeliveryStatus,
  MyWork,
  RouteName,
} from '@/models';

import { DateUtils, AccordionUtils } from '@/utils';

const { ASSIGNMENT_FILL, PUBLISHED_ASSIGNMENT } = RouteName;

@Component({
  name: 'AccordionAssignments',
  components: {
    Header,
    ChevronDownLight,
    WorkToFill,
    Footer,
  },
})
export default class AccordionAssignments extends Vue {
  $refs: {
    AccordionBody: HTMLDivElement;
  };

  @Prop({}) public title: string;
  @Prop({ default: () => [] }) public works: MyWork[];
  @Prop({ default: false }) public loading: boolean;
  @Prop({ default: false }) public filterByPublisheds: boolean;

  public expanded = true;
  public DateUtils = DateUtils;
  public ButtonDefaultColors = ButtonDefaultColors;

  public get filteredWorks() {
    const requirements = (w: MyWork) => (this.filterByPublisheds
      ? w.delivery?.status === DeliveryStatus.PUBLISHED
      : w.delivery?.status !== DeliveryStatus.PUBLISHED);

    return this.works.filter((w: MyWork) => requirements(w));
  }

  mounted() {
    this.toggleExpansion();
  }

  updated() {
    this.toggleExpansion(this.expanded);
  }

  public goToSee(deliveryId: number) {
    const name = PUBLISHED_ASSIGNMENT;
    const params = { entregaId: deliveryId.toString() };
    this.$router.push({ name, params });
  }

  public goToFill(trabalhoId: number) {
    const name = ASSIGNMENT_FILL;
    const params = { trabalhoId: trabalhoId.toString() };
    this.$router.push({ name, params });
  }

  public toggleExpansion(expanded = true, delay = 0) {
    if (this.works.length) {
      AccordionUtils.toggleExpandableElement(this.$refs.AccordionBody, expanded, delay);
    }
  }

  public toggleAccordion() {
    this.expanded = !this.expanded;
    this.toggleExpansion(this.expanded, 500);
  }
}
