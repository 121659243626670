
import { Vue, Component, Prop } from 'vue-property-decorator';

import LogoLinkedIn from '@/assets/icons/LogoLinkedIn.svg';

import { Aluno } from '@/models/api';

@Component({
  name: 'PendingAssignmentProfile',
  components: {
    LogoLinkedIn,
  },
})
export default class PendingAssignmentProfile extends Vue {
  @Prop({ default: false }) liked = false;
  @Prop() data: Aluno;
  @Prop() opt: object;

  public defaultImage = require('@/assets/img/no-profile-photo.png');

  public goToLinkedIn() {
    window.open(this.data.linkedin, '_blank');
  }
}
