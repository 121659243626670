
import { Vue, Component, Prop } from 'vue-property-decorator';

import LogoLinkedIn from '@/assets/icons/LogoLinkedIn.svg';
import ThumbsUp from '@/assets/icons/ThumbsUp.svg';
import Comment from '@/assets/icons/Comment.svg';

import ButtonDefault from '@/components/shared/ButtonDefault.vue';

import { ButtonEvents, Student, Reaction } from '@/models';

import { EventBus } from '@/utils/EventBus';

const { LIKE, COMMENT } = ButtonEvents;

@Component({
  name: 'PublishedAssignmentProfile',
  components: {
    ButtonDefault,
    LogoLinkedIn,
    ThumbsUp,
    Comment,
  },
})
export default class PublishedAssignmentProfile extends Vue {
  @Prop({ default: false }) liked = false;
  @Prop() data: Student;
  @Prop() opt: Reaction;
  @Prop() requesting: boolean;

  public like = LIKE;
  public comment = COMMENT;
  public defaultImage = require('@/assets/img/no-profile-photo.png');

  public emitClick(buttonEvent: ButtonEvents) {
    EventBus.$emit(buttonEvent);
  }

  public goToLinkedIn() {
    window.open(this.data.linkedIn, '_blank');
  }
}
