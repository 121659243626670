
import { Vue, Component, Prop } from 'vue-property-decorator';

import LogoLinkedIn from '@/assets/icons/LogoLinkedIn.svg';

import { Student } from '@/models';

@Component({
  name: 'HorizontalProfile',
  components: {
    LogoLinkedIn,
  },
})
export default class HorizontalProfile extends Vue {
  @Prop({ default: false }) liked = false;
  @Prop({ required: true }) data: Student;

  public defaultImage = require('@/assets/img/no-profile-photo.png');

  public openLink(url: string) {
    window.open(url, '_blank');
  }
}
